<template>
    <section class="section">
        <h1><i class="bi bi-lock-fill text-primary"></i> パスワード再設定</h1>

        <p>新しいパスワードを入力してください。</p>

        <form class="form" @submit.prevent="reset()">
            <div class="form-row align-items-end mb-3">
                <div class="form-group col-md-6">
                    <label>パスワード</label>
                    <input type="password" class="form-control" required="required" v-model="password">
                </div>
            </div>
            <div class="form-row">
                <button-exec-edit text="パスワード変更" />
            </div>
        </form>
    </section>
</template>

<script>
import axios from 'axios';
import ButtonExecEdit from '@/shared/components/design/buttons/ButtonExecEdit.vue';

export default {
    components: {
        ButtonExecEdit,
    },
    name: 'PageReminderVerify',
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
        'fetchUsers',
        'fetchErrorCount',
    ],
    data() {
        return {
            password: null,
            token: null,
        }
    },
    mounted() {
        // ログインしていたらPageHomeへリダイレクト
        if (this.$store.state.user.auth.is_signed_in) {
            this.$router.push({name: 'PageHome'});
        }

        this.token = this.$route.query.token;

        if (!this.token) {
            this.showErrorMessage('URLが不正です。');
            this.$router.push({name: 'PageReminder'});
            return;
        }

        axios.get(process.env.VUE_APP_API_BASE_URI + '/reminder/verify', {
            params: {
                token: this.token,
            }
        })
        .catch((error) => {
            let message = error?.response?.data?.message ?? 'エラーが発生しました';
            this.showErrorMessage(message);
            this.$router.push({name: 'PageReminder'});
        })
        .finally(() => {
            this.endScreenLoading();
        });
    },
    methods: {
        reset() {
            this.startScreenLoading();
            axios.post(process.env.VUE_APP_API_BASE_URI + '/reminder/reset', {
                password: this.password,
                token: this.token,
            })
            .then(() => {
                this.showMessage('パスワードを再設定しました。');
                this.$router.push({name: 'PageSignin'});
            })
            .catch((error) => {
                console.log(error.response.data);
                let message = error?.response?.data?.errors?.password[0] ?? 'エラーが発生しました';
                this.showErrorMessage(message);
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
